<template>
  <div class="network-page">
    <section v-if="!loading">
      <div v-if="tag">
        <b-card class="col-12" title="TheHub - Tag Details">
          <b-card-text>
            This page contains details on a tag used on TheHub.
          </b-card-text>
        </b-card>

        <div class="row flex-row-reverse">
          <div v-if="showActions" class="col-md-3">
            <b-card title="Actions">
              <b-button
                v-if="$can('Update', 'TheHub.Tags')"
                class="full-width mb-1"
                variant="primary"
                @click="showUpdateModal"
              >
                Update
              </b-button>
              <b-button
                v-if="$can('Delete', 'TheHub.Tags')"
                class="full-width mb-1"
                variant="danger"
                @click="showDeleteModal"
              >
                Delete
              </b-button>
            </b-card>
          </div>

          <div :class="`${showActions && 'col-md-9'}`" class="col-12">
            <b-card title="Tag Details">
              <table class="table table-striped mb-2">
                <tbody>
                  <tr>
                    <td class="col-4">Name</td>
                    <td>{{ tag.name }}</td>
                  </tr>
                  <tr>
                    <td>Featured</td>
                    <td>{{ tag.featured ? "Yes" : "No" }}</td>
                  </tr>
                  <tr>
                    <td>Article Count</td>
                    <td>{{ tag.article_count }}</td>
                  </tr>
                  <tr v-if="tag.parent">
                    <td>Parent</td>
                    <td @click="reset">
                      <router-link
                        :to="{
                          name: 'tag-details',
                          params: { id: tag.parent.id },
                        }"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ tag.parent.name }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="tag-image">
                <img
                  v-if="imageURL"
                  :src="imageURL"
                  alt=""
                  class="full-width full-height"
                >
                <div v-else class="d-flex flex-column align-items-center">
                  <img alt="" src="@/assets/images/icons/jpg.png">
                  <div class="mt-2">No Image Selected</div>
                </div>
              </div>

              <div v-if="imageURL" class="float-right">
                <b-button variant="danger" @click="showDeleteImageModal">
                  Delete Image
                </b-button>
              </div>
            </b-card>
          </div>
        </div>

        <div v-if="tag.parent === null" class="row">
          <div class="col-12">
            <b-card>
              <b-card-title>
                <div class="row">
                  <div class="col-6">Sub Tags</div>
                  <div class="col-6">
                    <b-btn
                      v-if="$can('Create', 'TheHub.Tags')"
                      class="float-right"
                      variant="success"
                      @click="showCreateSubTagModal"
                    >
                      Create Sub Tag
                    </b-btn>
                  </div>
                </div>
              </b-card-title>

              <b-table
                :current-page="childrenCurrentPage"
                :fields="childrenHeaders"
                :items="children"
                :per-page="childrenFilters.page_size"
                class="data-table"
                hover
              >
                <template #cell(actions)="data">
                  <div @click="reset">
                    <router-link
                      :to="{
                        name: 'tag-details',
                        params: { id: data.item.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      View tag
                    </router-link>
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col
                  v-if="children.length > childrenFilters.page_size"
                  class="my-1"
                  md="6"
                >
                  <b-pagination
                    v-model="childrenCurrentPage"
                    :per-page="childrenFilters.page_size"
                    :total-rows="children.length"
                    class="my-0 mx-1"
                  />
                </b-col>
                <p v-if="childrenTotalCount === 0" class="col-6 ml-2 my-1">
                  No Records
                </p>
              </b-row>
            </b-card>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-card>
              <b-card-title>
                <div class="row">
                  <div class="col-12">Articles</div>
                </div>
              </b-card-title>

              <b-table
                :current-page="articlesCurrentPage"
                :fields="articleHeaders"
                :items="articles"
                :per-page="articleFilters.page_size"
                class="data-table"
                hover
              >
                <template #cell(date)="data">
                  <span>
                    {{ data.item.date | formatDate }}
                  </span>
                </template>

                <template #cell(is_published)="data">
                  <span>
                    {{ data.item.is_published ? "Published" : "Draft" }}
                  </span>
                </template>

                <template #cell(actions)="data">
                  <router-link
                    :to="{
                      name: 'article-details',
                      params: { id: data.item.id },
                    }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    View article
                  </router-link>
                </template>
              </b-table>
              <b-row>
                <b-col
                  v-if="articles.length > articleFilters.page_size"
                  class="my-1"
                  md="6"
                >
                  <b-pagination
                    v-model="articlesCurrentPage"
                    :per-page="articleFilters.page_size"
                    :total-rows="articles.length"
                    class="my-0 mx-1"
                  />
                </b-col>
                <p v-if="articlesTotalCount === 0" class="col-6 ml-2 my-1">
                  No Records
                </p>
              </b-row>
            </b-card>
          </div>
        </div>

        <b-modal id="modal-update-tag" hide-footer title="Update Article">
          <tag-editor-modal
            :existing="tag"
            @close="closeModals"
            @update="updateTag"
          />
        </b-modal>

        <b-modal id="modal-create-sub-tag" hide-footer title="Create Sub Tag">
          <tag-editor-modal
            :parent-id="tag.parent_id"
            @close="closeModals"
            @update="createSubTag"
          />
        </b-modal>
      </div>
    </section>

    <!-- Loading -->
    <section v-else>
      <b-spinner label="Loading TheHub Article Comment" variant="primary" />
    </section>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import CountryService from "@/services/CountryService";
import TagEditorModal from "@/views/theHub/sections/TagEditorModal.vue";

export default {
  name: "ViewTag",
  components: { TagEditorModal },
  data() {
    return {
      tag: null,
      imageURL: "",
      loading: false,
      image: null,
      childrenHeaders: [
        {
          key: "name",
          label: "Name",
          class: "col-6",
        },
        {
          key: "article_count",
          label: "Article Count",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      children: [],
      childrenFilters: {
        page: 0,
        page_size: 15,
        parent_id: 0,
      },
      childrenCurrentPage: 1,
      childrenTotalCount: 0,
      articleHeaders: [
        {
          key: "title",
          label: "Title",
          class: "col-4",
        },
        this.$can("Read", "User") && {
          key: "author.name",
          label: "Author",
        },
        {
          key: "date",
          label: "Date",
        },
        this.$can("Read", "TheHub.Likes") && {
          key: "like_count",
          label: "Likes",
        },
        this.$can("Read", "TheHub.Comments") && {
          key: "comment_count",
          label: "Comments",
        },
        {
          key: "is_published",
          label: "Status",
        },
        this.$can("Read", "TheHub.Articles") && {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      articles: [],
      articleFilters: {
        page: 0,
        page_size: 15,
      },
      articlesCurrentPage: 1,
      articlesTotalCount: 0,
    };
  },
  computed: {
    showActions() {
      return (
        this.$can("Update", "TheHub.Tags") || this.$can("Delete", "TheHub.Tags")
      );
    },
    currentID() {
      return this.$route.params.id;
    },
  },
  watch: {
    async currentID() {
      await this.getTag();
      await this.getChildren();
      await this.getArticles();
    },
  },
  async mounted() {
    await this.getTag();
    await this.getChildren();
    await this.getArticles();
  },
  methods: {
    async getTag() {
      this.loading = true;
      try {
        const { data } = await TheHubService.getTag(this.$route.params.id);
        this.tag = data;

        if (this.tag.image) {
          this.imageURL = `${CountryService.getApiUrl()}/files/${
            this.tag.image.file_location
          }/${this.tag.image.filename}`;
        }
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get tag, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
        this.$router.push({ name: "tags" });
      } finally {
        this.loading = false;
      }
    },
    async getChildren() {
      this.loading = true;
      try {
        this.childrenFilters.parent_id = this.tag.id;
        let { data } = await TheHubService.listTags(this.childrenFilters);
        this.children = data.data;
        this.childrenCurrentPage = data.meta.page + 1;
        this.childrenTotalCount = data.meta.total_count;
        this.childrenFilters.page_size = data.meta.page_size;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get tags, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async getArticles() {
      this.loading = true;
      try {
        const { data } = await TheHubService.getArticlesByTagId(
          this.$route.params.id,
          this.articleFilters
        );
        this.articles = data.data;
        this.articlesCurrentPage = data.meta.page;
        this.articlesTotalCount = data.meta.total_count;
        this.articleFilters.page_size = data.meta.page_size;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get articles, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async updateTag(tag) {
      this.loading = true;
      try {
        if (tag.image) {
          await TheHubService.uploadTagImage(this.$route.params.id, tag.image);
        }

        let updateTagDTO = {
          name: tag.name,
          featured: tag.featured,
          parent_id: tag.parent_id,
        };
        const { data } = await TheHubService.updateTag(
          this.$route.params.id,
          updateTagDTO
        );
        this.tag = data;

        if (this.tag.image) {
          this.imageURL = `${CountryService.getApiUrl()}/files/${
            this.tag.image.file_location
          }/${this.tag.image.filename}`;
        }

        this.$toast.success(`Updated tag Successfully`, {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not update tag, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async createSubTag(tag) {
      this.loading = true;
      try {
        let dto = {
          name: tag.name,
          featured: tag.featured,
          parent_id: this.tag.id,
        };
        const { data } = await TheHubService.createTag(dto);
        if (tag.image) {
          await TheHubService.uploadTagImage(data.id, tag.image);
        }

        // Fetch sub tags
        await this.getChildren();

        this.$toast.success("Subtag was successfully created.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete tag, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteTag() {
      this.loading = true;
      try {
        await TheHubService.deleteTag(this.$route.params.id);
        this.$router.push({ name: "tags" });
        this.$toast.success("Tag was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete tag, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteImage() {
      this.loading = true;
      try {
        await TheHubService.deleteTagImage(this.$route.params.id);
        this.imageURL = "";
        this.$toast.success("Image was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete image, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    showUpdateModal() {
      this.$bvModal.show("modal-update-tag");
    },
    showCreateSubTagModal() {
      this.$bvModal.show("modal-create-sub-tag");
    },
    showDeleteModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this tag? This action cannot be undone.",
          {
            title: "Delete Tag",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteTag();
          }
        });
    },
    showDeleteImageModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this image? This action cannot be undone.",
          {
            title: "Delete Image",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteImage();
          }
        });
    },
    closeModals() {
      this.$bvModal.hide("modal-update-tag");
      this.$bvModal.hide("modal-create-sub-tag");
    },
    async reset() {
      this.image = null;

      await this.getTag();
      await this.getChildren();
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-image {
  aspect-ratio: 1/1;
  max-height: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;
}
</style>
