var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"network-page"},[(!_vm.loading)?_c('section',[(_vm.tag)?_c('div',[_c('b-card',{staticClass:"col-12",attrs:{"title":"TheHub - Tag Details"}},[_c('b-card-text',[_vm._v(" This page contains details on a tag used on TheHub. ")])],1),_c('div',{staticClass:"row flex-row-reverse"},[(_vm.showActions)?_c('div',{staticClass:"col-md-3"},[_c('b-card',{attrs:{"title":"Actions"}},[(_vm.$can('Update', 'TheHub.Tags'))?_c('b-button',{staticClass:"full-width mb-1",attrs:{"variant":"primary"},on:{"click":_vm.showUpdateModal}},[_vm._v(" Update ")]):_vm._e(),(_vm.$can('Delete', 'TheHub.Tags'))?_c('b-button',{staticClass:"full-width mb-1",attrs:{"variant":"danger"},on:{"click":_vm.showDeleteModal}},[_vm._v(" Delete ")]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"col-12",class:("" + (_vm.showActions && 'col-md-9'))},[_c('b-card',{attrs:{"title":"Tag Details"}},[_c('table',{staticClass:"table table-striped mb-2"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"col-4"},[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.tag.name))])]),_c('tr',[_c('td',[_vm._v("Featured")]),_c('td',[_vm._v(_vm._s(_vm.tag.featured ? "Yes" : "No"))])]),_c('tr',[_c('td',[_vm._v("Article Count")]),_c('td',[_vm._v(_vm._s(_vm.tag.article_count))])]),(_vm.tag.parent)?_c('tr',[_c('td',[_vm._v("Parent")]),_c('td',{on:{"click":_vm.reset}},[_c('router-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
                        name: 'tag-details',
                        params: { id: _vm.tag.parent.id },
                      }}},[_vm._v(" "+_vm._s(_vm.tag.parent.name)+" ")])],1)]):_vm._e()])]),_c('div',{staticClass:"tag-image"},[(_vm.imageURL)?_c('img',{staticClass:"full-width full-height",attrs:{"src":_vm.imageURL,"alt":""}}):_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icons/jpg.png")}}),_c('div',{staticClass:"mt-2"},[_vm._v("No Image Selected")])])]),(_vm.imageURL)?_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.showDeleteImageModal}},[_vm._v(" Delete Image ")])],1):_vm._e()])],1)]),(_vm.tag.parent === null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-card',[_c('b-card-title',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v("Sub Tags")]),_c('div',{staticClass:"col-6"},[(_vm.$can('Create', 'TheHub.Tags'))?_c('b-btn',{staticClass:"float-right",attrs:{"variant":"success"},on:{"click":_vm.showCreateSubTagModal}},[_vm._v(" Create Sub Tag ")]):_vm._e()],1)])]),_c('b-table',{staticClass:"data-table",attrs:{"current-page":_vm.childrenCurrentPage,"fields":_vm.childrenHeaders,"items":_vm.children,"per-page":_vm.childrenFilters.page_size,"hover":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('div',{on:{"click":_vm.reset}},[_c('router-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
                      name: 'tag-details',
                      params: { id: data.item.id },
                    }}},[_vm._v(" View tag ")])],1)]}}],null,false,2469744341)}),_c('b-row',[(_vm.children.length > _vm.childrenFilters.page_size)?_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0 mx-1",attrs:{"per-page":_vm.childrenFilters.page_size,"total-rows":_vm.children.length},model:{value:(_vm.childrenCurrentPage),callback:function ($$v) {_vm.childrenCurrentPage=$$v},expression:"childrenCurrentPage"}})],1):_vm._e(),(_vm.childrenTotalCount === 0)?_c('p',{staticClass:"col-6 ml-2 my-1"},[_vm._v(" No Records ")]):_vm._e()],1)],1)],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-card',[_c('b-card-title',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v("Articles")])])]),_c('b-table',{staticClass:"data-table",attrs:{"current-page":_vm.articlesCurrentPage,"fields":_vm.articleHeaders,"items":_vm.articles,"per-page":_vm.articleFilters.page_size,"hover":""},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.date))+" ")])]}},{key:"cell(is_published)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.is_published ? "Published" : "Draft")+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('router-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
                    name: 'article-details',
                    params: { id: data.item.id },
                  }}},[_vm._v(" View article ")])]}}],null,false,2620103236)}),_c('b-row',[(_vm.articles.length > _vm.articleFilters.page_size)?_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0 mx-1",attrs:{"per-page":_vm.articleFilters.page_size,"total-rows":_vm.articles.length},model:{value:(_vm.articlesCurrentPage),callback:function ($$v) {_vm.articlesCurrentPage=$$v},expression:"articlesCurrentPage"}})],1):_vm._e(),(_vm.articlesTotalCount === 0)?_c('p',{staticClass:"col-6 ml-2 my-1"},[_vm._v(" No Records ")]):_vm._e()],1)],1)],1)]),_c('b-modal',{attrs:{"id":"modal-update-tag","hide-footer":"","title":"Update Article"}},[_c('tag-editor-modal',{attrs:{"existing":_vm.tag},on:{"close":_vm.closeModals,"update":_vm.updateTag}})],1),_c('b-modal',{attrs:{"id":"modal-create-sub-tag","hide-footer":"","title":"Create Sub Tag"}},[_c('tag-editor-modal',{attrs:{"parent-id":_vm.tag.parent_id},on:{"close":_vm.closeModals,"update":_vm.createSubTag}})],1)],1):_vm._e()]):_c('section',[_c('b-spinner',{attrs:{"label":"Loading TheHub Article Comment","variant":"primary"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }